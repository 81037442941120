import React from "react";
import { Link } from "gatsby";
import InformationIcon from "../../images/svg/icons/information-icon.svg";

const AdvertiserCTA = () => {
  return (
    <div className="advertiser-cta">
      <div className="advertiser-cta__info">
        <div className="advertiser-cta__icon">
          <InformationIcon />
        </div>
        <div className="advertiser-cta__text">
          <h2>Looking to advertise on this website?</h2>
        </div>
      </div>
      <div className="advertiser-cta__button">
        <Link to="/en/contact" className="button button--white">
          Find out more
        </Link>
      </div>
    </div>
  );
};

export default AdvertiserCTA;
